import React, { Component} from 'react';
import { Redirect, Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, customFilter, selectFilter, Comparator, FILTER_TYPES } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
//import { Button, Spinner , Modal} from 'reactstrap';
import axios from 'axios';
// import LoadingBar from "react-top-loading-bar";
import Swal from 'sweetalert2';

import { RingLoader, FadeLoader, DotLoader, PropagateLoader } from 'react-spinners';
 import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import { FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button, Spinner } from 'reactstrap';
// import { Multiselect } from 'react-widgets';


export function AddLibrary(urlOfTheLibrary) {
  const script = document.createElement('script');
  script.src = urlOfTheLibrary;
  script.async = true;
  document.body.appendChild(script);
}


class uploaddoc extends Component {
  
fileObj = [];
fileArray = [];

  constructor(props) {
    super(props);
    this.state = {
      ShowBar: false,
      alert: null,
      redirect: false,
      PreliminaryImageErr:'',

      Savestatus:false,

      folder_name:'',
      subforldername:'',
      subforldername_files:'',
      subforldername_images:'',


      displayingfoldername:'',
  
    };


  }

  
  componentDidMount = async () => {
    console.log('in componentDidMount');
   

    // this.setState({
    // 	ShowBar: true,
    // 	progressBar: true,
      
    // })


    


  }

  onUploadPreliminaryimage = async (event) => {

   this.setState({
    	ShowBar: true,
    	progressBar: true,
      
    })

                // const filesFormats=["image/jpeg","image/png","image/jpg,.doc",".docx",".xls",".xlsx","application/vnd.openxmlformats-officedocument.spreadsheetml.sheet","sheet","wordprocessingml","application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/pdf"];
                // const isRightFormat = filesFormats.includes(event.target.files[0].type);
            
                // console.log(isRightFormat);
                // if(isRightFormat)
                // {
       

        if(this.state.folder_name !="")
        {
               
                this.fileObj.push(event.target.files)
                    for (let i = 0; i < this.fileObj[0].length; i++) {
                       //this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
                        this.fileArray.push(this.fileObj[0][i])
                    }                    
        
            }
            else
            {

                alert("Please select folder first");
                // this.setState({
                   
                //     PreliminaryImageErr:'Please select folder first',
                // });

            }
  
    this.setState({
        ShowBar:false,
        // ShowProcedure: false,
        // LoadingBar:false,
      })
  
  
  }


    
  onUploadPreliminaryImageAtServer = async (event) => {

    
        if(this.fileArray.length>0)
        {
            Array.from(this.fileArray).forEach(item=>{

                  // console.log("FinalPublicationExcelFile",this.state.manager_id,this.fileArray)
                  //const urlFile = global.websiteUrl+'/service/clientsharables/upload_sharables_file';            
                   const urlFile = global.websiteUrl+'/service/Client/get_upload_sharables_file';            
                   //https://clientsharables.embecta.cetas-healthcare.com/app/service/Client/get_upload_sharables_file
                   let formDataFile = new FormData();
                      formDataFile.append('file', item);
                      formDataFile.append('foldername', this.state.folder_name);
                      formDataFile.append('sub_foldername', this.state.subforldername);
                      
                      const configfile = {
                          headers: { 'content-type': 'multipart/form-data' }
                      }
                      this.setState({
                        ShowBar: true,
                        
                      })
                  
                      axios.post(urlFile, formDataFile, configfile)
                          .then(response => {
                          console.log(response);
                          
                          var data = response.data;
                          console.log("data publication excel", data);
                          
                          var status = response.data.status;
                          console.log("status publication", status);

                        //  //alert(response.data.status)

                          // if(response.data.status==true)
                          // {                            
                          //   this.setState({Savestatus:true})
                          // }
                          
                          this.setState({
                            ShowBar: false,
                           // folder_name:'',
                            //subforldername:'',
                            //displayingfoldername:'',
                            PreliminaryImageErr:'Successfully Uploaded',
                           
                          })
                        

                          setTimeout( function(){window.location.reload();},5000 );
                          

                          })
                         
                      })

                     
          }
          else
          {
                alert("Select Document first");
           
          }
        //   this.setState({
        //     folder_name:'',
        //     subforldername:'',
        //     displayingfoldername:'',
        //     PreliminaryImageErr:'Successfully Uploaded',
        //     // ShowProcedure: false,
        //     // LoadingBar:false,
        // })

      
  }

  
  render() {



   if (this.state.redirect) {
            return (<Redirect to={'/'}/>)
  }




  
    return (

        
      <body id="landing-body">
        <div>
          {this.state.ShowBar ? <Modal isOpen={true}  centered>
            <PropagateLoader color={'#A90007'} size='40px' loading='true' style={{ textAlign:'center'}} />
          </Modal> : null}
        </div>
       
    
    {/* <nav className="navbar navbar-expand-lg navbar-light sticky-top" >
      <div className="content" style={{width:'100%'}} > 
        <div className='container2'  style={{ marginTop:'15px'}}>
            <a className="navbar-brand" href="https://www.cetas-healthcare.com/"><img src={require('./Assets/Images/cetas_healthcare_logo.svg')} width="60" height="60"></img></a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav" style={{marginTop:'-63px'}} >
                <ul className="navbar-nav ms-auto" >
                </ul>
               
                    <a href="#" data-bs-toggle="modal" className="btn btn-brand ms-lg-3" style={{ cursor: 'pointer',paddingLeft:'30px',paddingRight:'30px' }} onClick={this.toggleLoginModal.bind(this)}>Log In</a>
            </div>
            </div>
        </div>
        </nav> */}



      <div className='container text-center'>
        <div class="row pt-12">

        <h1> Upload Document</h1>


      

  <div  style={{  width:'60%', marginLeft:'20%', marginTop:'40px',textAlign:'left' , backgroundColor:'#F5F7F8',paddingLeft:'5%',paddingTop:'5%',paddingBottom:'5%'}}>
  <div class="col-sm" style={{  width:'60%'}}>
                                <Input type="select" id="roleSelect" className="form-control"
                                  style={{ height: '40px' }}
                                  name="country_id"
                                //   value={this.state.PersonalInformation.country_id}
                                  onChange={async (e) => {
                                    await this.setState({ folder_name:e.target.value,
                                        displayingfoldername:e.target.value
                                            
                                    })
                                    //alert(this.state.folder_name)
                                  }}
                                >
                                    <option value="" >--Select Folder--</option>                                   
                                    <option value="Files" >Files</option>
                                    <option value="Images" >Images</option>
                                    <option value="Dashboard Versions" >Dashboard Versions</option>
                                    <option value="Data File" >Data File</option>
                                    <option value="Others" >Others</option>
                              
                              </Input>

                                  
                                  {(this.state.folder_name =="Files") ?

                                        (<div> 

                                    <div id="subfolder_file" style={{marginTop:'10px'}}>
                                        <Input type="select" id="roleSelectfiles" className="form-control"
                                        style={{ height: '40px' }}
                                        name="subfolder_files"
                                        //   value={this.state.PersonalInformation.country_id}
                                        onChange={async (e) => {
                                            await this.setState({ subforldername:e.target.value ,
                                            displayingfoldername:this.state.folder_name +"->" + e.target.value
                                        })
                                        }}
                                        >
                                        <option value="" >--Select Sub Folder Name--</option>
                                        <option value="Monthly" >Monthly</option>
                                        <option value="Quartery" >Quartery</option>
                                        <option value="Ad_hoc" >Ad-hoc</option>
                                        <option value="Other" >Other</option>
                                        
                                        </Input>
                                    </div>
                                            
                                             </div>):(<div></div>)
                                  
                                  
                                  }

                                  
{(this.state.folder_name =="Images") ?

(<div> 
    <div id="subfolder_image" style={{marginTop:'10px'}}>
                                        <Input type="select" id="roleSelectimage" className="form-control"
                                        style={{ height: '40px' }}
                                        name="subfolder_image"
                                        //   value={this.state.PersonalInformation.country_id}
                                        onChange={async (e) => {
                                            await this.setState({ subforldername:e.target.value,
                                                displayingfoldername:this.state.folder_name +"->" + e.target.value
                                            
                                            })
                                  
                                        }}
                                        >
                                        <option value="" >--Select Sub Folder Name--</option>
                                        <option value="Product Images" >Product Images</option>
                                        <option value="Brand Logos" >Brand Logos</option>
                                        <option value="Partner Product images" >Partner Product images</option>
                                     
                                        
                                        </Input>

                                    </div> 
    
     </div>):(<div></div>)


}

 {(this.state.displayingfoldername) ? 
    (<div>
    <Label>Selected Folder: <b> {this.state.displayingfoldername}</b> </Label> 

    </div>):(<div></div>)

}                                
                                               
                              </div>

                                 
            <div class="col-sm" style={{ width:'50%', float:'left',marginTop:'20px', display:'inline-block'}}>
            
            <div class="form-control" style={{width:'100%' , height:'40px'}}  >
                <input type='file'
                    accept="*"
                    multiple
                    id="myInputImage"
                    //accept="image/*"
                    onChange={this.onUploadPreliminaryimage} hidden 
                    
                    />
                <label htmlFor="myInputImage"
                    style={{ color: 'blue', fontSize: "14px", marginTop: '0%' }}>
                    Select Document
                </label>
                {
                    (this.fileArray.length !=0) ? (
                        <div class="float-right" style={{  marginTop:'10px',fontSize:'14px' }}>
                            {/* <text>{this.fileArray.length} file selected</text> */}
                            <text>Selected  file is :<b> {this.fileArray[0].name}</b>
                               {/* {
                                
                                  Array.from(this.fileArray).forEach(item1=>{

                                  var xu=item1.name
                                    {
                                      <div>xu</div>
                                    }  
                                  
                                  })

                                  
                                  
                            
                            }*/} </text> 
                        </div>
                    ) : (
                        <div class="float-right" style={{ marginTop:'10px',fontSize:'14px'}}>
                            No file Choosen
                        </div>
                    )
                    
                }
            </div>
            </div>
            <div class="col-sm" style={{width:'200px', display:'inline-block',float:'left',marginTop:'20px'}}>
            
            <Button style={{width:'100px' , height:'40px'}} onClick={this.onUploadPreliminaryImageAtServer} className='reset_button'> Upload</Button>
             {/* <Button   className='reset_button' >Reset Search</Button> */}
      
    </div>
            <div className="loginErrCreateProject" style={{ float:'left', marginTop:'30px', fontSize:20,fontWeight:'bold'}} >
                {/* {this.state.PreliminaryImageErr} */}
                {
                    (this.state.PreliminaryImageErr !== "")?(
                    <div>
                          {  (this.state.PreliminaryImageErr == "Successfully Uploaded")?(<div style={{ color:'green'}}>
                              {this.state.PreliminaryImageErr}
                
                            </div>):(<div> {this.state.PreliminaryImageErr}
                </div>)
                            }
                    </div>):(<div></div>)

                }
                </div>

          
          </div>





        {/* <iframe src='https://view.officeapps.live.com/op/embed.aspx?src=https://clientsharables.embecta.cetas-healthcare.com/testppt.pptx' width='100%' height='600px' frameborder='0'>  </iframe> */}
            {/* <iframe src='https://docs.google.com/gview?url=https://clientsharables.embecta.cetas-healthcare.com/Document/PFE.pdf&embedded=true' width='100%' height='600px' frameborder='0'></iframe> */}

{/* ******** PPTM File Showing Pdf type ***************/}
            {/* <iframe src='https://docs.google.com/gview?url=https://clientsharables.embecta.cetas-healthcare.com/Document/PFE - T_AS_document_dated_10-08-2023 1691653889604.pdf' width='100%' height='600px' frameborder='0'></iframe> */}
            {/* <iframe src='https://view.officeapps.live.com/op/embed.aspx?src=https://clientsharables.embecta.cetas-healthcare.com//Document/2023.04.07_Cetas-HC_embecta_CI Monitoring Mar 2023 C1.pptx' width='100%' height='600px' frameborder='0'>  </iframe> */}
       

          

            {/* <iframe src="https://docs.google.com/gview?url=http://infolab.stanford.edu/pub/papers/google.pdf&embedded=true" style="width:600px; height:500px;" frameborder="0"></iframe>     */}
                {/* <h1>clientsharables.embecta.cetas-healthcare.com</h1> */}

                {/* <img src={require('./Assets/Images/project3.jpg')}/>  */}
                {/* <iframe src="https://cetashc-my.sharepoint.com/personal/jyoti_prakash_cetas-healthcare_com/_layouts/15/Doc.aspx?sourcedoc={3613555a-cb57-40d7-9efc-0dfdd90898dd}&amp;action=embedview&amp;wdAr=1.7777777777777777" width="876px" height="488px" frameborder="0"></iframe> */}
                {/* <embed src="https://clientsharables.embecta.cetas-healthcare.com/testppt.pptx"></embed> */}

        {/* <iframe src='https://view.officeapps.live.com/op/embed.aspx?src=https://cetashc-my.sharepoint.com/:p:/g/personal/jyoti_prakash_cetas-healthcare_com/ETBdjGH-wPBOimYXkHUMc3oBs3AcHet5XZ3Kz2O_caHNuA?e=ReNjkx&embedded=true' width='100%' height='600px' frameborder='0'>  </iframe> */}



             {/* <iframe src='https://view.officeapps.live.com/op/embed.aspx?src=${https://clientsharables.embecta.cetas-healthcare.com/testppt.pptx&embedded=true}' width='100%' height='600px' frameborder='0'></iframe> */}
             {/* <iframe width="100%" height="630" src="https://docs.google.com/viewer?url=https://clientsharables.embecta.cetas-healthcare.com/testppt.pptx&embedded=true" ></iframe> */}
        </div>
        </div>




    {/* <footer>
        <div className="footer-top text-center">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 text-center" style={{height:'20px'}}>
                        <p className="mb-0">Copyright Cetas 2022. All rights Reserved</p>
                    </div>
                </div>
            </div>
        </div>
    </footer> */}


  



  
    
    




      {/* </header> */}
 
      </body>
    );
  }
}

export default uploaddoc;