import React, { Component, useRef } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button, Col, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
//import LoadingBar from "react-top-loading-bar";
// import PhoneInput from 'react-phone-input-2';
//import { Multiselect } from 'react-widgets';
//import Spinner from '../Assets/Spinner'
import { RingLoader, FadeLoader, DotLoader, PropagateLoader } from 'react-spinners';


// import './landingpage.css';


export function AddLibrary(urlOfTheLibrary) {
  const script = document.createElement('script');
  script.src = urlOfTheLibrary;
  script.async = true;
  document.body.appendChild(script);
}


class ClientDocumentDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ShowBar: false,
      alert: null,
      redirect: false,
      filename:'',
  
    };


  }

  
  componentDidMount = async () => {
    console.log('in componentDidMount');

    const query = new URLSearchParams(this.props.location.search);
    const id=query.get('id');
    const docname = query.get('name');

    // alert(id);

    // alert(docname);

    this.setState({
    	ShowBar: true,
    	progressBar: true,
      
    })
    
    //let path="https://view.officeapps.live.com/op/embed.aspx?src=https://clientsharables.embecta.cetas-healthcare.com/Document/";
  
  let path="";  
  
  if(docname.includes(".pptx") || docname.includes(".xlsx")  )
  {
    path="https://view.officeapps.live.com/op/embed.aspx?src=https://clientsharables.embecta.cetas-healthcare.com/app/emecta_Dashboard/Files/"+id+"/";
        
  }
  // else if(docname.includes(".xlsx"))
  // {
  //  path="https://docs.google.com/gview?url=https://clientsharables.embecta.cetas-healthcare.com/Document/";
  
  // }
  else
  {
   path="https://docs.google.com/gview?url=https://clientsharables.embecta.cetas-healthcare.com/app/emecta_Dashboard/Files/"+id+"/";
  
  }

  var complete_path=path + docname +"&embedded=true";  
  // alert(complete_path);  
  this.setState({filename:complete_path})

    this.setState({
    	ShowBar: false,
    	progressBar: false,
      
    })

  }



  
render() {

 if (this.state.redirect) {
            return (<Redirect to={'/'}/>)
  }

  return (

        
      <body id="landing-body">
        <div>
          {this.state.ShowBar ? <Modal isOpen={true}  centered>
            <PropagateLoader color={'#A90007'} size='40px' loading='true' style={{ textAlign:'center'}} />
          </Modal> : null}
        </div>
       
    
    {/* <nav className="navbar navbar-expand-lg navbar-light sticky-top" >
      <div className="content" style={{width:'100%'}} > 
        <div className='container2'  style={{ marginTop:'15px'}}>
            <a className="navbar-brand" href="https://www.cetas-healthcare.com/"><img src={require('./Assets/Images/cetas_healthcare_logo.svg')} width="60" height="60"></img></a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav" style={{marginTop:'-63px'}} >
                <ul className="navbar-nav ms-auto" >
                </ul>
               
                    <a href="#" data-bs-toggle="modal" className="btn btn-brand ms-lg-3" style={{ cursor: 'pointer',paddingLeft:'30px',paddingRight:'30px' }} onClick={this.toggleLoginModal.bind(this)}>Log In</a>
            </div>
            </div>
        </div>
        </nav> */}



      <div className='container1 text-center'>
        <div class="row1">
        {/* <iframe src='https://view.officeapps.live.com/op/embed.aspx?src=https://clientsharables.embecta.cetas-healthcare.com/testppt.pptx' width='100%' height='600px' frameborder='0'>  </iframe> */}
            {/* <iframe src='https://docs.google.com/gview?url=https://clientsharables.embecta.cetas-healthcare.com/Document/PFE.pdf&embedded=true' width='100%' height='600px' frameborder='0'></iframe> */}

{/* ******** PPTM File Showing Pdf type ***************/}
            {/* <iframe src='https://docs.google.com/gview?url=https://clientsharables.embecta.cetas-healthcare.com/Document/2023.04.07_Cetas-HC_embecta_CI Monitoring Mar 2023 C1.pptx&embedded=true' width='100%' height='600px' frameborder='0'></iframe> */}
            <iframe src={this.state.filename} width='100%' height='600px' frameborder='0'>  </iframe>
       
         
          

            {/* <iframe src="https://docs.google.com/gview?url=http://infolab.stanford.edu/pub/papers/google.pdf&embedded=true" style="width:600px; height:500px;" frameborder="0"></iframe>     */}
                {/* <h1>clientsharables.embecta.cetas-healthcare.com</h1> */}

                {/* <img src={require('./Assets/Images/project3.jpg')}/>  */}
                {/* <iframe src="https://cetashc-my.sharepoint.com/personal/jyoti_prakash_cetas-healthcare_com/_layouts/15/Doc.aspx?sourcedoc={3613555a-cb57-40d7-9efc-0dfdd90898dd}&amp;action=embedview&amp;wdAr=1.7777777777777777" width="876px" height="488px" frameborder="0"></iframe> */}
                {/* <embed src="https://clientsharables.embecta.cetas-healthcare.com/testppt.pptx"></embed> */}

        {/* <iframe src='https://view.officeapps.live.com/op/embed.aspx?src=https://cetashc-my.sharepoint.com/:p:/g/personal/jyoti_prakash_cetas-healthcare_com/ETBdjGH-wPBOimYXkHUMc3oBs3AcHet5XZ3Kz2O_caHNuA?e=ReNjkx&embedded=true' >  </iframe> */}



             {/* <iframe src='https://view.officeapps.live.com/op/embed.aspx?src=${https://clientsharables.embecta.cetas-healthcare.com/testppt.pptx&embedded=true}' width='100%' height='600px' frameborder='0'></iframe> */}
             {/* <iframe width="100%" height="630" src="https://docs.google.com/viewer?url=https://clientsharables.embecta.cetas-healthcare.com/testppt.pptx&embedded=true" ></iframe> */}
        </div>
        </div>




    {/* <footer>
        <div className="footer-top text-center">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 text-center" style={{height:'20px'}}>
                        <p className="mb-0">Copyright Cetas 2022. All rights Reserved</p>
                    </div>
                </div>
            </div>
        </div>
    </footer> */}


  



  
    
    




      {/* </header> */}
 
      </body>
    );
  }
}

export default ClientDocumentDisplay;