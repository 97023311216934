import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';


import hwB1K5NkfcIzkLTWQeQfHLNg5FlyX3ky1aDbE5KNA from './hwB1K5NkfcIzkLTWQeQfHLNg5FlyX3ky1aDbE5KNA';
import ClientDocumentDisplay from './doc/ClientDocumentDisplay';

import uploaddoc from './doc/uploaddoc';



// import Login from './Login';
// import QuestionTopicsList from './Employee/QuestionTopicsList';
// import QuestionList from './Employee/QuestionList';
// import ShowCartitems from './Employee/ShowCartitems';





const Routes = () => (

    
    // <BrowserRouter basename="/my-qb">
    <BrowserRouter basename="/">
   
    <Switch>
    <Route exact path='/' component={hwB1K5NkfcIzkLTWQeQfHLNg5FlyX3ky1aDbE5KNA}/>    
    
    <Route exact path='/doc' component={ClientDocumentDisplay}/>    

    <Route exact path='/uploaddoc' component={uploaddoc}/> 

    {/* <Route exact path='/Login' component={Login}/>     */}

       
     {/* <Route path='/QuestionTopicsList' component={QuestionTopicsList} /> */}
    {/*<Route path='/QuestionList' component={QuestionList} />
    <Route path='/ShowCartitems' component={ShowCartitems}/> */}

    </Switch>
    </BrowserRouter>

    

   
);
export default Routes;